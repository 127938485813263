import React, { useState, useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
// core components
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';
import Button from 'components/CustomButtons';
import Plan from './Plan';

const useStyle = makeStyles(productStyle);

const SelectPlan = ({ plans, descriptions, onSelect }) => {
  const [basePrice, setBasePrice] = useState(0);

  useEffect(() => {
    let base = plans.sort((a, b) => {
      if (a.price < b.price) {
        return -1;
      } else {
        return 1;
      }
    })[0].price;
    setBasePrice(base);
  }, [plans]);

  const classes = useStyle();

  return (
    <div>
      {/* <h1 className={classes.title}>{plan.title}</h1> */}
      {/* <h3>{plan.tier[tier].subtitle}</h3> */}
      <GridContainer justify="center">
        <List className={classes.description} dense disablePadding>
          {descriptions.map(d => (
            <ListItem key={d}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>{d}</Typography>} />
            </ListItem>
          ))}
        </List>
      </GridContainer>
      <div className={classes.section}>
        <GridContainer justify="center">
          {plans
            .sort((a, b) => {
              if (a.period > b.period) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((p, index) => {
              const discount = parseInt(
                ((basePrice * +p.period - +p.price) / (basePrice * +p.period)) *
                  100
              );
              return (
                <GridItem key={p.title} xs={12} sm={12} md={4}>
                  <Plan discount={discount} plan={p} onSelect={onSelect} />
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
      <div className={classes.serverSection}></div>
    </div>
  );
};

export default SelectPlan;
