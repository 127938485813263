import React from 'react';
import Pricing from '../views/Upgrade';
import { graphql } from 'gatsby';

// eslint-disable-next-line react/display-name
export default ({ data }) => <Pricing data={data} />;

export const query = graphql`
  query PriceQuery {
    pricing: pricingJson {
      personal {
        en {
          title
          subtitle
          tier {
            host {
              tier
              descriptions
              newAccount {
                title
                content
              }
              complete {
                title
                content
              }
            }
            cert {
              tier
              descriptions
              newAccount {
                title
                content
              }
              complete {
                title
                content
              }
            }
          }
          plans {
            title
            profile
            period
            plan
            price
            billed
          }
        }
        zh_TW {
          title
          subtitle
          plans {
            title
            profile
            period
            plan
            price
            billed
          }
          tier {
            host {
              tier
              descriptions
              newAccount {
                title
                content
              }
              complete {
                title
                content
              }
            }
            cert {
              tier
              descriptions
              newAccount {
                title
                content
              }
              complete {
                title
                content
              }
            }
          }
        }
      }
    }
  }
`;
