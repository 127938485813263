const config = {
  STRIPE_KEY: process.env.STRIPE_KEY,
  s3: {
    REGION: process.env.S3_REGION,
    BUCKET: process.env.S3_BUCKET,
  },
  apiGateway: {
    ENDPOINT: process.env.API_ENDPOINT,
    REGION: process.env.API_REGION,
    URL: process.env.API_URL,
    KEY: process.env.API_KEY,
  },
  cognito: {
    REGION: process.env.COGNITO_REGION,
    USER_POOL_ID: process.env.COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.COGNITO_IDENTITY_POOL_ID,
  },
  social: {
    FB: process.env.FB,
    GG: process.env.GG,
    AM: process.env.AM,
  },
};

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
