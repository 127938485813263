import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FormattedNumber, FormattedMessage } from 'gatsby-plugin-intl';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// @material-ui/icons
import Typography from '@material-ui/core/Typography';
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons';
import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';

import {
  clearPaymentIntent,
  changeSubscriptionStart,
  loadUserStart,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import {
  selectAppError,
  selectStatus,
  selectTheme,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import SelectPeriod from './SelectPeriod';
import blueGrey from '@material-ui/core/colors/blueGrey';
const useStyle = makeStyles(productStyle);

const Plan = ({ plan, discount, onSelect, currentTheme }) => {
  const classes = useStyle();

  // for first time user
  return (
    <Card
      style={{
        backgroundColor:
          currentTheme === 'light'
            ? plan.period === 12
              ? '#81c784'
              : '#a6d4fa'
            : plan.period === 12
            ? '#388e3c'
            : blueGrey[400],
      }}
    >
      <CardHeader
        style={{ fontWeight: 500, fontSize: '0.9rem' }}
        color={plan.period === 12 ? 'success' : 'info'}
      >{`${plan.title.toUpperCase()}`}</CardHeader>

      <CardBody>
        <div className={classes.container}>
          <div>
            <Typography className={classes.price}>
              <FormattedNumber
                value={(plan.price / plan.period).toFixed(2)}
                style="currency"
                currencyDisplay="symbol"
                currency="USD"
              />
              {/* {`$${(plan.price / plan.period).toFixed(2)}`} */}
            </Typography>
            <Typography className={classes.description}>
              <FormattedMessage
                id="upgrade.perMonth"
                defaultMessage="per month"
              />
            </Typography>
          </div>
          <div style={{ display: 'block', marginLeft: '2rem' }}>
            <Typography
              className={classes.description}
              style={{ fontWeight: 500, fontSize: '1.2rem', color: 'red' }}
            >
              <FormattedMessage
                id="upgrade.discount"
                defaultMessage="Save {discount}%"
                values={{
                  discount: ` ${discount}`,
                }}
              />
            </Typography>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <Button
          round
          fullWidth
          color={plan.period === 12 ? 'google' : 'facebook'}
          style={{ fontWeight: 500, fontSize: '0.9rem' }}
          onClick={() => {
            onSelect(plan.plan, plan.price);
          }}
        >
          <FormattedMessage id="upgrade.select" defaultMessage="Select" />
        </Button>
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  error: selectAppError,
  appStatus: selectStatus,
  currentTheme: selectTheme,
});

const mapDispatchToProps = dispatch => ({
  clearPaymentIntent: () => dispatch(clearPaymentIntent()),
  loadUser: () => dispatch(loadUserStart()),
  changeSubscription: ({ plan }) => dispatch(changeSubscriptionStart({ plan })),
  resetApp: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
