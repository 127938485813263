import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby-plugin-intl';
import { Parallax } from '@jackywxd/shared-material-ui';

import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
import Tabs from './Personal/Tabs';
import SelectPlanTab from './Personal/Tabs';

import Button from 'components/CustomButtons';
import config from '../../config';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectCurrentUser,
  selectPaymentIntent,
} from '@allconnect/store/lib/user/user.selectors';
import {
  selectEmail,
  selectUser,
  selectPaymentRequest,
} from '@allconnect/store/lib/payment/payment.selectors';
import {
  paymentSetEmail,
  paymentSetPaymentRequest,
  paymentReset,
} from '@allconnect/store/lib/payment/payment.actions';
import {
  signupStart,
  clearPaymentIntent,
  emailSignInStart,
  onPaymentIntentStart,
  onGetPaymentIntentStatus,
  loadUserStart,
  addSubscriptionStart,
} from '@allconnect/store/lib/user/user.actions';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import {
  selectStatus,
  selectLang,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import Confirmation from './Personal/Confirmation';
import ChangePlan from './Personal/ChangePlan';
import CompletedPurchase from './Personal/CompletedPurchase';
import UpgradePlan from './Personal/UpgradePlan';

const stripePromise = loadStripe(config.STRIPE_KEY);

const useStyles = makeStyles(landingPageStyle);

// data is pricing json file data
const Pricing = ({
  data,
  lang,
  currentUser,
  signUpStart,
  loadUser,
  loginStart,
  resetApp,
  appStatus,
  addSubscription,
}) => {
  const classes = useStyles();
  const {
    pricing: { personal },
  } = data;

  const [personalPlans, setPlans] = useState(() => {
    if (lang) return personal[lang];
  });

  useEffect(() => {
    if (!lang) {
      return;
    } else if (lang === 'en') {
      setPlans(personal['en']);
    } else if (lang === 'zh-CN') {
      setPlans(personal['zh_CN']);
    } else if (lang === 'zh-TW') {
      setPlans(personal['zh_TW']);
    }
  }, [lang]);

  const [tier, setTier] = useState('Standard'); // cert or exclusive tier
  const [state, setState] = useState('selectPlan');
  const [plan, setPlan] = useState(null);
  const [user, setUser] = useState({ email: '', password: '' });

  const onSelect = (selectedPlan, price) => {
    personalPlans.plans.map(plan => {
      if (plan.plan === selectedPlan) {
        setPlan(plan);
        setState('confirmation');
        return;
      }
    });
  };

  useEffect(() => {
    if (
      appStatus === UserActionTypes.SIGNUP_SUCCESS &&
      user.email &&
      user.password
    ) {
      // once user sign up successfully, we login the user automatically
      loginStart(user.email, user.password);
    } else if (appStatus === UserActionTypes.EMAIL_SIGN_IN_SUCCESS) {
      setState('completed');
    } else if (appStatus === UserActionTypes.ADD_SUBSCRIPTION_SUCCESS) {
      setState('completed');
    }
  }, [appStatus]);

  const onAction = () => {
    setState('selectPlan');
  };

  const onComplete = ({ token, paymentMethod, email, password }) => {
    // if currentUser is login, we call addSubscription
    if (currentUser && plan) {
      addSubscription({ token, paymentMethod, plan: plan.plan });
      return;
    }

    setUser({ email, password });
    // if paymentMethod is set, we should verify the paymentMethod
    let init = {
      email,
      password,
      plan: plan.plan,
    };
    if (paymentMethod) {
      signUpStart({
        ...init,
        type: 'personal',
        paymentMethod,
      });
    } else if (token) {
      signUpStart({
        ...init,
        type: 'personal',
        token,
      });
    }
  };

  const completeSubscription = async () => {
    resetApp();
    await loadUser();
    navigate('/app/home');
  };
  if (!personalPlans) {
    return null;
  }
  if (currentUser && currentUser.status === 'subscribed') {
    navigate('/app/home');
  }

  return (
    <Layout>
      <div
        // style={{ background: 'rgba(0, 0, 0, 0.3)' }}
        className={classes.pageHeader}
      >
        <div className={classes.container}>
          <Elements stripe={stripePromise}>
            {(!currentUser ||
              currentUser.type === 'free' ||
              currentUser.status === 'unsubscribed') && (
              <>
                {state === 'selectPlan' && (
                  <SelectPlanTab
                    plan={personalPlans}
                    onSelect={onSelect}
                    setTier={setTier}
                  />
                )}
                {/* currentUser is valid, change existing user's plan*/}
                {state === 'confirmation' && (
                  <Confirmation
                    currentUser={currentUser}
                    plan={plan}
                    newAccount={
                      tier === 'Standard'
                        ? personalPlans.tier.cert.newAccount
                        : personalPlans.tier.host.newAccount
                    }
                    action={onAction}
                    onComplete={onComplete}
                  />
                )}
              </>
            )}

            {state === 'completed' && (
              <CompletedPurchase
                messages={
                  tier === 'Standard'
                    ? personalPlans.tier.cert.complete
                    : personalPlans.tier.host.complete
                }
                onSelect={completeSubscription}
              />
            )}
          </Elements>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
  paymentIntent: selectPaymentIntent,
  email: selectEmail,
  user: selectUser,
  lang: selectLang,
  paymentRequest: selectPaymentRequest,
});

const mapDispatchToProps = dispatch => ({
  signUpStart: ({
    email,
    password,
    profile,
    plan,
    type,
    token,
    paymentMethod,
    name,
    phone,
  }) =>
    dispatch(
      signupStart({
        email,
        password,
        profile,
        plan,
        type,
        token,
        paymentMethod,
        name,
        phone,
      })
    ),
  loginStart: (email, password) => dispatch(emailSignInStart(email, password)),
  clearPaymentIntent: () => dispatch(clearPaymentIntent()),
  resetPayment: () => dispatch(paymentReset()),
  resetApp: () => dispatch(clearError()),
  paymentSetEmail: email => dispatch(paymentSetEmail(email)),
  setPaymentRequest: ({ plan, paymentRequest }) =>
    dispatch(paymentSetPaymentRequest({ plan, paymentRequest })),
  addSubscription: ({ plan, token, paymentMethod, name, phone }) =>
    dispatch(addSubscriptionStart({ plan, token, paymentMethod, name, phone })),
  loadUser: () => dispatch(loadUserStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
