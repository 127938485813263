import React, { useState, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useIntl, FormattedNumber, FormattedMessage } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import MuiTextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// core components
import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';

import Plan from './Plan';
import Button from 'components/CustomButtons';
import PaymentRequest from './PaymentRequest';
import StripeCard from '../CardPayment/StripeCard';
import EmailForm from './EmailForm';

const useStyle = makeStyles(productStyle);

/*
By clicking “Start my membership,” you agree to our Membership Terms of Service. Y
our payment method will be charged a recurring USD $50 yearly fee, 
unless you decide to cancel. No refunds for memberships canceled between billing cycles.
*/
const Confirmation = ({
  currentUser,
  plan,
  newAccount,
  action,
  onComplete,
}) => {
  if (!plan) {
    return null;
  }
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  const [paymentRequest, setPaymentRequest] = useState(null);

  // check if paymentRequest Button is avaible if yes, we shoud this button first
  useEffect(() => {
    if (stripe) {
      const payment = stripe.paymentRequest({
        country: 'CA',
        currency: 'usd',
        total: {
          label: `ALLCONNECT: ${plan.title}`,
          amount: plan.price * 100,
        },
        // requestPayerName: true,
        // requestPayerEmail: true,
        // requestPayerPhone: true,
      });

      // Check the availability of the Payment Request API first.
      payment.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(payment);
        }
      });
    }
    // return () => {
    //   setPaymentRequest({});
    // };
  }, [plan]);

  const onSubmit = async props => {
    if (process.env.NODE_ENV !== 'production') {
    }
    // this is paymentRequest purchase
    if (props && props.token) {
      const token =
        process.env.NODE_ENV === 'production' ? props.token : 'tok_visa'; // use test token in testing env
      return onComplete({ email, password, token });
    }

    // this is pay by card purchase
    const { error, paymentMethod } = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardNumberElement),
    });
    if (error && error.message) {
      // console.log(error);
      setError({ card: error.message });
      // setState({ ...state, cardNumberError: error.message });
    }
    if (paymentMethod) {
      // console.log(paymentMethod, email, password);
      // for subscription we just return the paymentMethod ID, it will be used to create customer
      onComplete({ email, password, paymentMethod: paymentMethod.id });
    }
  };

  function validatePassword(event) {
    let error;
    const { value } = event.target;
    setPassword(value);
    return error;
  }
  function validateEmail(event) {
    let error;
    const { value } = event.target;
    setEmail(value);
    return error;
  }

  const classes = useStyle();

  const row = (title, content) => {
    return (
      <Box display="flex">
        <Box display="flex" justifyContent="flex-end" width="45%" padding={1}>
          <Typography variant="h6">
            <FormattedMessage id={title.id} defaultMessage={title.default} />
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          marginLeft={3}
          padding={1}
        >
          <Typography variant="h6">{content}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <div style={{ marginTop: '10vh' }} className={classes.section}>
        <Box display="flex" justifyContent="center">
          <Box width={{ xs: '100%', md: '50%' }} m={1} bgcolor="transparent">
            <Box>
              <h1 className={classes.title}>
                <FormattedMessage
                  id="upgrade.confirmation.summary"
                  defaultMessage="Summary"
                />
              </h1>
            </Box>
            {row(
              { id: 'upgrade.confirmation.plan', default: 'Plan: {plan}' },
              plan.title
            )}
            {row(
              { id: 'upgrade.confirmation.billed', default: '{plan}' },
              plan.billed
            )}
            {row(
              {
                id: 'upgrade.confirmation.totalPrice',
                default: 'Plan: {plan}',
              },
              intl.formatNumber(plan.price, {
                style: 'currency',
                currency: 'USD',
              })
            )}
            {/* <Typography variant="h5">
              <FormattedMessage
                id="upgrade.confirmation.billed"
                defaultMessage="Billed: {billed}"
                values={{
                  billed: plan.billed,
                }}
              />
            </Typography> */}
          </Box>
        </Box>
        <Grid container spacing={2} justify="center">
          {!currentUser && (
            <Grid item xs={12} sm={12} md={6}>
              <Paper
                variant="outlined"
                style={{ marginTop: '1rem', padding: '1rem' }}
              >
                <Typography variant="h6">{newAccount.title}</Typography>
                <Box
                  fontSize={{ xs: '0.5rem', md: '0.9rem' }}
                  textAlign="left"
                  p={1}
                  display="flex"
                  flexDirection="column"
                >
                  {newAccount.content.map(c => (
                    <Typography key={c} variant="caption" gutterBottom>
                      {c}
                    </Typography>
                  ))}
                </Box>
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <MuiTextField
                    name="email"
                    type="email"
                    label={<FormattedMessage id="login.emailLabel" />}
                    value={email}
                    fullWidth
                    onChange={validateEmail}
                  />
                  <MuiTextField
                    name="password"
                    type="password"
                    label={<FormattedMessage id="login.passwordLabel" />}
                    value={password}
                    onChange={validatePassword}
                    fullWidth
                  />
                </div>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <Paper
              variant="outlined"
              style={{ marginTop: '1rem', padding: '1rem' }}
            >
              <Typography variant="h6">
                <FormattedMessage
                  id="upgrade.confirmation.payment"
                  defaultMessage="Payment Information"
                />
              </Typography>
              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <PaymentRequest
                  paymentRequest={paymentRequest}
                  onComplete={onSubmit}
                />
              </div>
              {paymentRequest && <Divider />}
              <Typography style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                {paymentRequest ? (
                  <FormattedMessage
                    id="upgrade.confirmation.paymentRequest"
                    defaultMessage="Or enter card number below"
                  />
                ) : (
                  <FormattedMessage
                    id="upgrade.confirmation.payByCard"
                    defaultMessage="Enter card number below"
                  />
                )}
              </Typography>
              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <StripeCard
                  error={error.card}
                  price={plan.price}
                  disabled={!currentUser && (!email || !password)}
                  handleSubmit={onSubmit}
                />
              </div>
              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Button round fullWidth onClick={() => action(false)}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Confirmation;
