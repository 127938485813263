import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { FormattedNumber, FormattedMessage } from 'gatsby-plugin-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// core components
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import Button from 'components/CustomButtons';
import { Typography } from '@material-ui/core';

const useStyle = makeStyles(productStyle);

const SelectPlan = ({ messages, onSelect }) => {
  const classes = useStyle();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ marginTop: '20vh' }}
    >
      <Box width={{ xs: '100%', md: '60%' }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <CheckCircleIcon
            width="50%"
            className={classes.title}
            style={{ color: '#155517', margin: '1rem', fontSize: '2.5rem' }}
          />
          <div>
            <h1 className={classes.title}>{messages.title}</h1>
          </div>
        </Box>
        <ul style={{ textAlign: 'justify' }}>
          {messages.content.map(content => (
            <li key={content}>
              <Typography>{content}</Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box width={{ xs: '100%', md: '50%' }} className={classes.section}>
        <Button
          round
          size="lg"
          color="google"
          onClick={() => {
            onSelect();
          }}
        >
          <FormattedMessage id="button.myAccount" defaultMessage="My Account" />
        </Button>
      </Box>
      <div className={classes.serverSection}></div>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(SelectPlan);
