import React from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const PaymentRequestForm = props => {
  const { paymentRequest, onComplete } = props;
  if (!paymentRequest) {
    return null;
  }
  // for subscription, we need the token
  paymentRequest.on('token', ev => {
    const { token } = ev;
    // event.token is available ev.complete('success');
    if (token) {
      console.log(token);
      onComplete({
        token: token.id,
      });
      ev.complete('success');
    } else {
      ev.complete('fail');
    }
  });

  return (
    <>
      <PaymentRequestButtonElement
        className="PaymentRequestButton"
        options={{ paymentRequest }}
      />
    </>
  );
};

export default React.memo(PaymentRequestForm);
