import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';
import Button from 'components/CustomButtons';
import Plan from './Plan';

const useStyles = makeStyles(productStyle);
import SelectPlan from './SelectPlan';
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ plan, onSelect, setTier }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  if (!plan) {
    return null;
  }
  const [tiers] = useState(() => {
    return [plan.tier.cert.tier, plan.tier.host.tier];
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setTier(tiers[newValue]);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div
      style={{
        marginTop: '18vh',
      }}
    >
      <h1 className={classes.title}>{plan.title}</h1>
      <h3>{plan.subtitle}</h3>
      <div
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Plan tabs"
          >
            {tiers.map(tier => (
              <Tab key={tier} label={tier} {...a11yProps} />
            ))}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={value} dir={theme.direction}>
            <SelectPlan
              plans={plan.plans.filter(f => f.profile === 'cert')}
              descriptions={plan.tier.cert.descriptions}
              onSelect={onSelect}
            />
          </TabPanel>
          <TabPanel value={value} index={value} dir={theme.direction}>
            <SelectPlan
              plans={plan.plans.filter(f => f.profile === 'host')}
              descriptions={plan.tier.host.descriptions}
              onSelect={onSelect}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}
