import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStripe } from '@stripe/react-stripe-js';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { FormattedMessage } from 'gatsby-plugin-intl';

import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from './commonTextFields';
import Button from 'components/CustomButtons';

const CreditCardDetail = props => {
  const { handleSubmit, error, disabled } = props;
  const stripe = useStripe();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldExpiry />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldCVC />
      </Grid>
      {error && (
        <Grid item xs={12} md={12}>
          {error}
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Button
          round
          fullWidth
          color="google"
          disabled={!stripe || disabled}
          onClick={handleSubmit}
          style={{ fontWeight: 500, fontSize: '0.9rem' }}
          endIcon={<CreditCardIcon />}
        >
          <FormattedMessage id="pay" defaultMessage="Pay Now" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(CreditCardDetail);
