import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import TextField from '@material-ui/core/TextField';
import StripeInput from './StripeInput';

function StripeTextField(props) {
  const { InputLabelProps, stripeElement, InputProps, ...others } = props;

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      {...others}
    />
  );
}

export function StripeTextFieldNumber({ ...props }) {
  return (
    <StripeTextField
      label={
        <FormattedMessage
          id="pay.creditCard"
          defaultMessage="Credit Card Number"
        />
      }
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry({ ...props }) {
  return (
    <StripeTextField
      label={<FormattedMessage id="pay.expires" defaultMessage="Expires" />}
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC({ ...props }) {
  return (
    <StripeTextField
      label={<FormattedMessage id="pay.CVC" defaultMessage="CVC Code" />}
      stripeElement={CardCvcElement}
      {...props}
    />
  );
}
